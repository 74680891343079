import React from "react";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useGetGroup, useGetUserStats, useGetRecentTopics } from "hooks/api";
import MoreIcon from "@mui/icons-material/Forward";
import { IconButton } from "@mui/material";
import SmallNavigationCard from "./SmallNavigationCard";
import StatCard from "./StatCard";
import SkeletonCard from "./SkeletonCard";
import SkeletonStatCard from "./SkeletonStatCard";
import * as routes from "routes";
import MyToolbar from "components/MyToolbar";
import MyToolbarCenter from "components/MyToolbarCenter";
import MyToolbarRight from "components/MyToolbarRight";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/system";
import { ColumnContainer } from "advancement-solutions-components/dist/components";
import RecentTopics from "./RecentTopics";
import { Button } from "components/Forms";

const SmallStatCards = ({ children }) => (
  <Box
    sx={{
      maxWidth: 700,
      margin: 1,
      display: "flex",
      flexFlow: "row wrap",
      justifyContent: "center",
    }}
  >
    {children}
  </Box>
);

const SmallCards = ({ isMobile, children }) => (
  <Box
    sx={{
      maxWidth: 700,
      margin: 1,
      display: "flex",
      flexFlow: "row wrap",
      justifyContent: isMobile ? "center" : "left",
    }}
  >
    {children}
  </Box>
);

const NavigationCert = () => {
  const brand = process.env.REACT_APP_BRAND;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [topics, loading, error] = useGetRecentTopics(brand, 4);
  const [stats] = useGetUserStats();
  const hasRecentTopics = topics && topics.length > 0;
  return (
    <ColumnContainer>
      <div>
        <SmallCards isMobile={isMobile}>
          <Button
            component={Link}
            to="/certification/topics"
            variant="contained"
            color="primary"
          >
            Browse Certifications
          </Button>
        </SmallCards>
        {hasRecentTopics && (
          <>
            <MyToolbar>
              <MyToolbarCenter left title="Recent Certifications" />
            </MyToolbar>
            <RecentTopics
              topics={topics}
              group="certification"
              showExam={true}
            />
          </>
        )}
        <MyToolbar>
          <MyToolbarCenter left title="Focused Study" />
        </MyToolbar>
        <SmallCards isMobile={isMobile}>
          <SmallNavigationCard
            title="Difficult Questions"
            description="Study questions that you've previously marked as hard."
            url="/questions/difficult"
          />
          <SmallNavigationCard
            title="Fading&nbsp; Questions"
            description="Study questions that you haven't see in a while."
            url="/questions/fading"
          />
        </SmallCards>
        <MyToolbar>
          <MyToolbarCenter left title="Progress" />
          <MyToolbarRight>
            <IconButton component={Link} to={routes.PROGRESS} size="large">
              <MoreIcon />
            </IconButton>
          </MyToolbarRight>
        </MyToolbar>
        {!stats ? (
          <SmallStatCards>
            <SkeletonStatCard />
            <SkeletonStatCard />
            <SkeletonStatCard />
          </SmallStatCards>
        ) : (
          <SmallStatCards>
            <StatCard
              title="Easy"
              value={stats.user.easy}
              variant="easy"
              url="/questions/easy"
            />
            <StatCard
              title="Normal"
              value={stats.user.normal}
              variant="normal"
              url="/questions/normal"
            />
            <StatCard
              title="Hard"
              value={stats.user.hard}
              variant="hard"
              url="/questions/hard"
            />
          </SmallStatCards>
        )}
      </div>
    </ColumnContainer>
  );
};

export default NavigationCert;
