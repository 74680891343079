import React from "react";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Button,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import { handlingConditions } from "../../utils";
import * as routes from "routes";

const TopicIcon = ({ handling, ...rest }) => {
  const condition = handlingConditions[handling];
  return <condition.icon {...rest} />;
};

const getStyles = (handling) => {
  const condition = handlingConditions[handling];
  return condition.style;
};

const Topic = ({ topic, group, showExam = false }) => {
  const styles = getStyles(topic.handling);
  const url = handlingConditions[topic.handling].link(group, topic);

  return (
    <React.Fragment key={topic.id}>
      <ListItemButton
        component={Link}
        to={url}
        sx={{
          ...styles.border,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ListItemIcon>
            <TopicIcon handling={topic.handling} sx={styles.icon} />
          </ListItemIcon>
          <ListItemText primary={topic.title} />
        </Box>
        {showExam && (
          <Button
            component={Link}
            to={{
              pathname: routes.MOCK_EXAM,
              state: { topic: topic },
            }}
          >
            Start Exam
          </Button>
        )}
      </ListItemButton>
      <Divider />
    </React.Fragment>
  );
};

const RecentTopics = ({ topics, group, showExam }) => {
  return (
    <List>
      {topics.map((topic) => (
        <Topic key={topic.id} topic={topic} group={group} showExam={showExam} />
      ))}
    </List>
  );
};

export default RecentTopics;
